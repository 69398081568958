import { render, staticRenderFns } from "./DataTableRowActions.vue?vue&type=template&id=cfe24dfe&"
import script from "./DataTableRowActions.vue?vue&type=script&lang=js&"
export * from "./DataTableRowActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports