<template>
  <ul v-show="filteredOptions" class="sm:text-sm flex justify-center space-x-1">
    <li
      v-for="(option, index) in filteredOptions"
      :key="`option-${index}`"
      class="has-tooltip flex relative"
    >
      <a
        href="#"
        @click.prevent="emit(option)"
        class="border rounded block p-1 bg-blue-800 hover:bg-blue-200 font-xs text-blue-100 hover:text-blue-800"
        v-html="option.icon"
      >
      </a>
      <span class="tooltip rounded px-2 py-1 bg-gray-900 text-white -mt-8 -left-1/3 text-xs">{{
        option.label
      }}</span>
    </li>
  </ul>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DataTableRowActions',
  props: ['id', 'iid', 'index', 'actions'],
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    emit(option) {
      this.$emit('event', { callback: option.event, index: this.index, id: this.id, iid: this.iid })
    },
  },
  computed: {
    filteredOptions() {
      return _.filter(this.actions, action => this.can(action.permission))
    },
  },
}
</script>

<style></style>
